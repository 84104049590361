import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import SEO from '../components/_shared/seo';

const Container = styled.div`
  background: #21211f;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-left: 8rem;
`;

const Header = styled.h1`
  font-family: 'Abril Fatface';
  color: #f5f1ec;
  font-size: 5.7rem;
`;

const Subheader = styled(motion.p)`
color: #f5f1ec;
font-family: 'Red Hat Display', 'Open Sans';
`;


const NotFoundPage = () => {
  const caption = [
    'Strona nie istnieje',
    'The page does not exist',
    'Diese Seite existiert nicht',
    "Cette page n'existe pas",
    'Данная страница не существует',
    'ця сторінка не існує',
    'Ez az oldal nem létezik',
  ];

  const [captionIndex, setCaptionIndex] = useState(0);
  const [currentCaption, setCurrentCaption] = useState(caption[captionIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (captionIndex + 2 > caption.length) {
        setCaptionIndex(0);
      } else {
        setCaptionIndex(captionIndex + 1);
      }
      setCurrentCaption(caption[captionIndex]);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [captionIndex]);

  return (
    <>
      <SEO title="Strona nie istnieje | Bitwa Warszawska" />
      <Container>
        <Header>404</Header>
        <AnimatePresence exitBeforeEnter>
          <Subheader
            key={currentCaption}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.5,
            }}
          >
            {currentCaption}
          </Subheader>
        </AnimatePresence>
      </Container>
    </>
  );
};

export default NotFoundPage;
